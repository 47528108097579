<template>
  <ValidationObserver
    tag="div"
    class="question column is-half question-credential"
    v-slot="{ errors }"
  >
    <div class="question-text">
      <p class="help is-danger" v-if="getFirstError(errors)">
        {{ getFirstError(errors) }}
      </p>
    </div>
    <div class="question-content card">
      <div class="card-content">
        <ValidationProvider
          v-for="(subQuestion, subQuestionIndex) in question.sub_questions"
          :key="subQuestion.id"
          :rules="{ ...validationRules }"
          v-slot="{ errors, valid }"
          tag="div"
          class="my-3"
        >
          <div class="field">
            <div class="field-label is-normal" :class="[isRtl ? 'text-right' : 'text-left']">
              <label
                class="label"
                :class="{ 'has-text-danger has-text-weight-bold': errors[0] }"
              >
                <div v-html="subQuestion.question[lang]"></div>
              </label>
            </div>
            <div class="field-body">
              <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }">
                <b-input
                  expanded
                  :placeholder="$t(`credential.placeholder`)"
                  :type="!subQuestionIndex ? 'text': 'password'"
                  :value="!subQuestionIndex ? value['username'] : value['password']"
                  @input="
                    onChange(
                      !subQuestionIndex ? 'username' : 'password',
                      $event
                    )
                  "
                  :disabled="loading"
                  :use-html5-validation="false"
                  :step="`any`"
                ></b-input>
              </b-field>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider mode="passive" :rules="{ credential: {response_id: respondent._id, sid, username: value['username'], password: value['password'], required: !!qOpts.mandatory } }">
          <b-input class="is-hidden"></b-input>
        </ValidationProvider>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
import { QuestionMixin } from "@/mixins/question";
import { isRtl } from '@/utils/survey';
export default {
  name: "Credential",
  mixins: [QuestionMixin],
  methods: {
    onChange(key, value) {
      this.$emit("input", { [key]: `${value}` });
    },
    getFirstError(errors) {
      const firstExistsErrors = Object.values(errors).find((item) => item[0]);
      if (firstExistsErrors) return firstExistsErrors[0];
    },
  },
  computed: {
    isRtl() {
      return isRtl();
    }
  }
};
</script>
<style lang="scss">
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.question-credential {
  max-width: 690px;
  .field-label {
    min-width: 208px;
    margin-bottom: 8px;
    label {
      &.label {
        font-size: 14px;
        color: #595959;
      }
      div {
        overflow-wrap: break-word;
      }
    }
  }
}
</style>
